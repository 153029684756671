import React from "react";

import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import SeoDeveloper from "../components/common/SeoDeveloper";

const NotFoundPage = () => {
  return (
    <>
      <SeoDeveloper />
      <Header />

      <div className="container min-h-withHeaderFooter flex flex-col justify-center items-center gap-10 md:gap-10 xl:gap-12 py-10 md:py-10 xl:py-20">
        <h1 className="text-5xl">Ooops!</h1>
        <p className="text-3xl">The page you requested could not be found.</p>
      </div>
      <Footer />
    </>
  );
};

export default NotFoundPage;
